<div class="box">
  <div class="logo">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.4 58" style="enable-background:new 0 0 90.4 58"
      xml:space="preserve">
      <path fill="#fff"
        d="M64.6 13.7V58H48.8V15.4c0-1.5-.3-2.6-.7-3.3-.5-.7-1.3-1.1-2.4-1.1-.7 0-1.6.2-2.7.6-1 .4-2 .9-2.9 1.5v45H24.6V15.4c0-3-1.1-4.4-3.3-4.4-1.7 0-3.6.7-5.5 2.1v45H0v-57h15.8v4.5C19.6 1.8 24.1 0 29.3 0c2.3 0 4.3.6 6 1.7 1.7 1.2 3 2.9 3.8 5.1C43.1 2.3 48 0 53.6 0c3.4 0 6 1.2 8 3.7 2 2.4 3 5.7 3 10z" />
      <path class="accent" d="M74.4 46.3h16V58h-16zM74.3 1.1h16.1v35.5H74.3z" />
    </svg>
  </div>

  <p>{{'mdz.auth.text'|translate}}</p>

  <!-- <button (click)="openLink()">{{'mdz.auth.button.open'|translate}}</button> -->
</div>