import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

private blurred: boolean = false;

  @HostListener('window:mousemove', ['$event'])
  handleMouseMoveDown(event: KeyboardEvent) {
    this.blurred = false;
  }
  @HostListener('window:blur', ['$event'])
  handleBlurDown(event: KeyboardEvent) {
    this.blurred = true;
  }
  @HostListener('window:focus', ['$event'])
  handleFocusDown(event: KeyboardEvent) {
    if(this.blurred) {
      // User selected somthing from the confirm dialog
      location.href = 'https://www.mindz.de'
    }
  }

  constructor(public translate: TranslateService) {
    this.translate.setDefaultLang('en');
    this.translate.use(this.translate.getBrowserLang());
  }

  openLink() {
    location.href = `mindz://callback${location.search}`;
  }

  ngOnInit() {
    setTimeout(() => {
      this.openLink();
    }, 1000)
  }
}
